import { create } from "zustand";

type Breadcrumb = {
  label: string;
  url: string;
};

type BreadcrumbsList = {
  breadcrumbsList: Breadcrumb[];
  setBreadcrumbsList: (breadcrumbsList: Breadcrumb[]) => void;
  setResetBreadcrumbsList: () => void;
};

const useBreadcrumbsList = create<BreadcrumbsList>((set) => ({
  breadcrumbsList: [],
  setBreadcrumbsList: (breadcrumbsList) => set({ breadcrumbsList }),
  setResetBreadcrumbsList: () => set({ breadcrumbsList: [] }),
}));

export default useBreadcrumbsList;

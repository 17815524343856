import { vestResolver } from "@hookform/resolvers/vest";
import MOOption from "@metsooutotec/modes-web-components/dist/react/option";
import MOSelect from "@metsooutotec/modes-web-components/dist/react/select";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create, test } from "vest";

import { LinkRequest } from "@/api/types/commonTypes";
import { Button, Input } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { AddNewDerivedVariablePropsForm } from "@/components/Forms/AddNewDerivedVariable/types";
import MathOperationInput from "@/components/MathOperationInput/MathOperationInput";
import {
  replaceVariableKeyToValueInExpressionByExpressionInput,
  validateExpression,
} from "@/components/MathOperationInput/utils";
import { useFetchResourceConfiguration } from "@/queries/resourcesQuery/resourcesQuery";
import {
  useAddVariable,
  useGetVariables,
} from "@/queries/variablesQuery/variablesQuery";
import { IfOutOfRangeList, RECEIVING_TYPE } from "@/utils/consts";
import {
  inputIsNotEmpty,
  inputMustBeEqualOrLessThan,
  inputMustBeEqualOrMoreThan,
} from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

const AddNewDerivedVariable = ({ organizationId, resourceId }: LinkRequest) => {
  const { t } = useTranslation();
  const { hideDialog } = useDialog();
  const { data: resourceVariablesData } = useGetVariables({
    organizationId,
    resourceId,
  });

  const { data: resourceConfig } = useFetchResourceConfiguration({
    organizationId,
    resourceId,
  });

  const { mutate, isPending } = useAddVariable();

  const variableList = resourceVariablesData
    ?.filter(
      (variable) =>
        variable.configuration && variable.configuration.expression.length === 0
    )
    .map((variable) => ({
      value: variable.variableName,
      name: variable.variableName,
    }));

  const schema = create((data: AddNewDerivedVariablePropsForm) => {
    inputIsNotEmpty("variableName", data.variableName);
    inputIsNotEmpty("minLimit", data.minLimit);
    inputIsNotEmpty("maxLimit", data.maxLimit);
    inputIsNotEmpty("outOfRange", data.outOfRange);
    inputIsNotEmpty("expression", data.expression);
    inputMustBeEqualOrMoreThan(
      "minLimit",
      Number(data.minLimit),
      Number(resourceConfig?.defaultVariableConfiguration.minLimit)
    );
    inputMustBeEqualOrLessThan(
      "maxLimit",
      Number(data.maxLimit),
      Number(resourceConfig?.defaultVariableConfiguration.maxLimit)
    );

    test("expression", () => {
      const validationResult = validateExpression(
        data.expression,
        data.expressionInput
      );

      if (!validationResult.isValid) {
        throw validationResult.error ?? "Invalid expression";
      }
    });
  });

  const methods = useForm<AddNewDerivedVariablePropsForm>({
    mode: "onSubmit",
    defaultValues: {
      variableName: "",
      expression: "",
      minLimit: "",
      maxLimit: "",
      outOfRange: "",
      averaging: "",
      expressionInput: {},
      duration: "",
      durationType: "",
    },
    resolver: vestResolver(schema),
  });

  const {
    setError,
    formState: { errors },
  } = methods;

  const submit = (data: AddNewDerivedVariablePropsForm) => {
    const expression = replaceVariableKeyToValueInExpressionByExpressionInput(
      data.expression,
      data.expressionInput
    );

    if (
      !data.expression.length ||
      !validateExpression(expression, data.expressionInput)
    ) {
      setError("expression", { message: "Invalid expression" });
      return;
    }

    // In the design, there is no option to select the receiving type.
    const receivingTypeStatus = RECEIVING_TYPE.continuous;

    const prepareData = {
      variableName: data.variableName,
      configuration: {
        maxAge: 0,
        minLimit: Number(data.minLimit),
        maxLimit: Number(data.maxLimit),
        invalidMeasurementHandling: Number(data.outOfRange),
        expression: replaceVariableKeyToValueInExpressionByExpressionInput(
          data.expression,
          data.expressionInput
        ),
        expressionInput: data.expressionInput,
        type: receivingTypeStatus,
      },
    };

    mutate({
      organizationId,
      resourceId,
      body: prepareData,
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(submit)}
        data-testid="form-add-new-derived-variable"
      >
        <div className={styles.formStyles}>
          <h3 data-testid="title-add-derived-variable">
            {t(LangKeys.ADD_DERIVED_PLANT_VARIABLE)}
          </h3>

          <Input
            errorText={errors.variableName?.message}
            label={t(LangKeys.VARIABLE_NAME)}
            data-testid="input-variable-name"
            {...methods.register("variableName")}
          />

          <MathOperationInput variables={variableList} />

          <div className={styles.twoInputsWrapper}>
            <div className={styles.inputWrapper}>
              <Input
                errorText={errors.minLimit?.message}
                label={t(LangKeys.MIN_VALUE)}
                placeholder={t(LangKeys.VALUE)}
                data-testid="input-min-limit"
                {...methods.register("minLimit")}
              />
            </div>

            <div className={styles.inputWrapper}>
              <Input
                errorText={errors.maxLimit?.message}
                label={t(LangKeys.MAX_VALUE)}
                placeholder={t(LangKeys.VALUE)}
                data-testid="input-max-limit"
                {...methods.register("maxLimit")}
              />
            </div>
          </div>

          <MOSelect
            label={t(LangKeys.IF_OUT_OF_RANGE)}
            onMoAfterHide={(e: any) => e.stopPropagation()}
            errorText={errors.outOfRange?.message}
            placeholder={t(LangKeys.CONDITION)}
            data-testid="select-out-of-range"
            {...methods.register("outOfRange")}
          >
            {IfOutOfRangeList.map((item) => (
              <MOOption
                key={item.value}
                value={`${item.value}`}
                data-testid={`option-out-of-range-${item.value}`}
              >
                {item.name}
              </MOOption>
            ))}
          </MOSelect>

          <div className={styles.dialogFooter}>
            <Button
              type="button"
              variant="subtle"
              onClick={hideDialog}
              data-testid="button-cancel-add-derived-variable"
            >
              {t(LangKeys.CANCEL)}
            </Button>

            <Button
              type="submit"
              variant="primary"
              loading={isPending}
              data-testid="button-apply-add-derived-variable"
            >
              {t(LangKeys.APPLY)}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddNewDerivedVariable;

import { ColDef, RowClickedEvent } from "ag-grid-community";
import { CustomFilterProps } from "ag-grid-react";
import { useTranslation } from "react-i18next";

import { Project } from "@/api/projectSlice/types";
import { EditUserPermissions, Icon, TableButton } from "@/components";
import FilterByList, {
  getFilterOptions,
} from "@/components/DataGrid/GridFilters/FilterByList/FilterByList";
import FilterByString from "@/components/DataGrid/GridFilters/FilterByString/FilterByString";
import { useDialog } from "@/components/Dialog/DialogProvider";
import DeleteUser from "@/components/Forms/DeleteUser";
import { calculateRowSpan } from "@/pages/UsersPage/utils";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./styles.module.scss";

type UsersColumnsProps = {
  organizationId?: string;
  projects?: Project[];
  orgData?: {
    projects: Project[];
    users: any[];
  };
};

const useUsersColumns = ({ organizationId, projects }: UsersColumnsProps) => {
  const { t } = useTranslation();
  const { showDialog } = useDialog();

  const editDeleteButtons = (api: RowClickedEvent) => {
    return (
      <div className={styles.tableButtonGroup}>
        <TableButton
          onClick={(e) => {
            e.stopPropagation();
            showDialog(
              <EditUserPermissions
                userData={api.data}
                organizationId={organizationId ?? ""}
                organizationProjects={projects ?? []}
              />,
              "medium"
            );
          }}
        >
          <Icon name="edit" className={styles.icon} />
        </TableButton>

        <TableButton
          onClick={(e) => {
            e.stopPropagation();
            showDialog(
              <DeleteUser
                userData={api.data}
                organizationId={organizationId ?? ""}
              />,
              "medium"
            );
          }}
        >
          <Icon name="trash" className={styles.icon} />
        </TableButton>
      </div>
    );
  };

  const columns: ColDef[] = [
    {
      headerName: "ID",
      cellDataType: "string",
      field: "id",
      hide: true,
      cellClass: "cell-style",
    },
    {
      headerName: t(LangKeys.FULL_NAME),
      cellDataType: "string",
      field: "fullName",
      rowSpan: (params: any) => calculateRowSpan(params, "id"),
      cellClassRules: {
        "cell-span": (params: any) => calculateRowSpan(params, "id") > 1,
      },
      flex: 1,
      cellRenderer: (params: any) => params.value || "",
      filter: ({ onModelChange, ...params }: CustomFilterProps) => (
        <FilterByString
          placeholder={t(LangKeys.FULL_NAME_SEARCH)}
          onModelChange={onModelChange}
          {...params}
        />
      ),
    },
    {
      headerName: t(LangKeys.EMAIL_ADDRESS),
      cellDataType: "string",
      flex: 1,
      field: "emailAddress",
      rowSpan: (params: any) => calculateRowSpan(params, "emailAddress"),
      cellClassRules: {
        "cell-span": (params: any) => calculateRowSpan(params, "id") > 1,
      },
      filter: ({ onModelChange, ...params }: CustomFilterProps) => (
        <FilterByString
          placeholder={t(LangKeys.FULL_NAME_SEARCH)}
          onModelChange={onModelChange}
          {...params}
        />
      ),
    },
    {
      headerName: t(LangKeys.PROJECT),
      cellDataType: "string",
      field: "project",
      sortable: false,
      filter: ({ onModelChange, ...params }: CustomFilterProps) => (
        <FilterByString
          placeholder={t(LangKeys.PROJECT_SEARCH)}
          onModelChange={onModelChange}
          {...params}
        />
      ),
    },
    {
      headerName: t(LangKeys.PERMISSIONS),
      cellDataType: "string",
      flex: 1,
      field: "permissions",
      sortable: false,
      filter: (params: CustomFilterProps) => (
        <FilterByList
          filterOptions={getFilterOptions("PERMISSIONS")}
          {...params}
        />
      ),
      cellRenderer: (params: {
        value: { permissionId: string; permissionName: string }[];
      }) => {
        return (
          <>
            {params.value.map((permission, index) => (
              <span key={permission.permissionId}>
                {permission.permissionName}
                {index !== params.value.length - 1 && ","}{" "}
              </span>
            ))}
          </>
        );
      },
    },
    {
      headerName: "Edit",
      rowSpan: (params: any) => calculateRowSpan(params, "id"),
      cellClassRules: {
        "cell-span": (params: any) => calculateRowSpan(params, "id") > 1,
      },
      headerComponent: () => (
        <div className={styles.tableButtonGroup}>
          <TableButton>
            <Icon name="edit" className={styles.icon} />
          </TableButton>

          <TableButton>
            <Icon name="trash" className={styles.icon} />
          </TableButton>
        </div>
      ),
      field: "ediColumn",
      maxWidth: 120,
      cellRenderer: editDeleteButtons,
      cellClass: "cell-style",
    },
  ];

  return columns;
};

export default useUsersColumns;

import { IDoesFilterPassParams } from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { useCallback } from "react";

import {
  IfOutOfRangeList,
  OPTIONS_LISTS_KEYS,
  PermissionsList,
  ReceivingTypeList,
} from "@/utils/consts";

import styles from "./styles.module.scss";

type FilterByListProps = CustomFilterProps & {
  filterOptions: { name: string; value: number | string }[];
};

const FilterByList = ({
  model,
  onModelChange,
  getValue,
  filterOptions,
}: FilterByListProps) => {
  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const { node } = params;
      const values = getValue(node);

      if (!model || model.length === 0) return true;

      if (Array.isArray(values)) {
        return values.some(
          (value: { permissionId: string; permissionName: string }) =>
            model.includes(value.permissionName)
        );
      }

      return model.includes(values);
    },
    [model]
  );

  useGridFilter({
    doesFilterPass,
  });

  const handleCheckboxChange = (value: number | string) => {
    if (model?.includes(value)) {
      onModelChange(model.filter((item: string | number) => item !== value));
    } else {
      onModelChange([...(model || []), value]);
    }
  };

  return (
    <div className={styles.filterWrapper}>
      {filterOptions.map(({ name, value }) => (
        <label key={value} className={styles.customLabel}>
          <input
            type="checkbox"
            name="filter"
            value={value}
            checked={model?.includes(value)}
            onChange={() => handleCheckboxChange(value)}
            className={styles.checkboxInput}
          />
          <span className={styles.customCheckbox}>{name}</span>
        </label>
      ))}
    </div>
  );
};

export default FilterByList;

export const getFilterOptions = (
  filterType: keyof typeof OPTIONS_LISTS_KEYS
) => {
  switch (filterType) {
    case OPTIONS_LISTS_KEYS.IF_OUT_OF_RANGE:
      return IfOutOfRangeList;
    case OPTIONS_LISTS_KEYS.RECEIVING_TYPE:
      return ReceivingTypeList;
    case OPTIONS_LISTS_KEYS.PERMISSIONS:
      return PermissionsList;
    default:
      return [];
  }
};

import {
  IAfterGuiAttachedParams,
  IDoesFilterPassParams,
} from "ag-grid-community";
import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { useCallback, useRef } from "react";

import { Icon, Input } from "@/components";

import styles from "./styles.module.scss";

type FilterByStringProps = {
  placeholder?: string;
} & CustomFilterProps;

const FilterByString = ({
  model,
  onModelChange,
  getValue,
  placeholder,
}: FilterByStringProps) => {
  const refInput = useRef(null);

  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const { node } = params;
      const filterText: string = model;
      const value: string = getValue(node).toString().toLowerCase();
      return filterText
        .toLowerCase()
        .split(" ")
        .every((filterWord) => value.includes(filterWord));
    },
    [model]
  );

  const afterGuiAttached = useCallback((params?: IAfterGuiAttachedParams) => {
    if (!params || !params.suppressFocus) {
      setTimeout(() => {
        (refInput.current as HTMLInputElement | null)?.focus();
      }, 0);
    }
  }, []);

  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });

  return (
    <div className={styles.filterWrapper}>
      <Input
        ref={refInput}
        type="text"
        value={model ?? ""}
        onMoInput={({ target }) =>
          onModelChange((target as HTMLInputElement)?.value ?? null)
        }
        placeholder={placeholder}
        autocomplete="off"
      >
        <Icon name="search" slot="suffix"></Icon>
      </Input>
    </div>
  );
};

export default FilterByString;

import "./styles.scss";
import "./datagridstyles.module.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import { GridOptions, SelectionChangedEvent } from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";

type DataTableProps = {
  rowData: any[];
  gridOptions: GridOptions;
  tableHeight?: number;
  onGridReady?: (e: any) => void;
  onSelectionChanged?: (e: SelectionChangedEvent) => void;
} & AgGridReactProps;

const DataGrid = ({ rowData, gridOptions, ...props }: DataTableProps) => {
  return (
    <div className={`ag-theme-quartz`} style={{ height: "40vh" }}>
      <AgGridReact
        rowData={rowData}
        gridOptions={gridOptions}
        rowClass="row-style"
        {...props}
      />
    </div>
  );
};

export default DataGrid;

import { t } from "i18next";

import { RECEIVING_TYPE } from "./consts";
import { LangKeys } from "./i18n/languageKeys";

export const getReceivingTypeName = (type: number) => {
  switch (type) {
    case RECEIVING_TYPE.discard:
      return t(LangKeys.DISCARD);
    case RECEIVING_TYPE.continuous:
      return t(LangKeys.CONTINUOUS);
    case RECEIVING_TYPE.discrete:
      return t(LangKeys.DISCRETE);
    default:
      return "";
  }
};

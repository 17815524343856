import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { VariablesTabs } from "@/components";
import { useOrganizations } from "@/queries/organizationsQuery/organizationsQuery";
import { useResources } from "@/queries/resourcesQuery/resourcesQuery";
import useBreadcrumbsList from "@/store/useBreadcrumbsList.store";

const VariablesPage = () => {
  const { organizationId, resourceId } = useParams<{
    organizationId: string;
    resourceId: string;
  }>();
  const { setBreadcrumbsList } = useBreadcrumbsList();

  const { data: resourcesData } = useResources(organizationId ?? "");
  const { data: organizationData } = useOrganizations();

  const organizationName = useMemo(() => {
    return organizationData?.find(
      (organization) => organization.id === organizationId
    )?.displayName;
  }, [organizationData, organizationId]);

  const resourceName = useMemo(() => {
    return resourcesData?.find((resource) => resource.resourceId === resourceId)
      ?.displayName;
  }, [resourcesData, resourceId]);

  useEffect(() => {
    setBreadcrumbsList([
      {
        label: organizationName || "",
        url: `/organization/${organizationId}`,
      },
      {
        label: resourceName || "",
        url: "",
      },
    ]);
  }, [organizationName, resourceName, organizationId, setBreadcrumbsList]);

  return <VariablesTabs />;
};

export default VariablesPage;

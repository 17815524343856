import { Organization } from "@/api/organizationSlice/types";
import { LightUserData } from "@/api/projectSlice/types";

import { HOUR_IN_SECONDS, MINUTE_IN_SECONDS, STATUS_TYPES } from "./consts";

/**
 * Retrieves the value of a checkbox input from an event.
 *
 * @param {Event} event - The event triggered by a user interaction, typically a change event.
 *
 * @returns {boolean} - The checked state of the checkbox (`true` if checked, `false` if unchecked).
 *
 * @example
 * // Get the value of a checkbox from an event
 * document.querySelector('#myCheckbox').addEventListener('change', (event) => {
 *   const isChecked = getCheckboxValue(event);
 *   console.log(isChecked); // true or false
 * });
 */
export const getCheckboxValue = (event: Event): boolean => {
  const target = event.target as HTMLInputElement;
  return target.checked;
};

/**
 * Creates an object that maps status values to their corresponding keys.
 *
 * @constant
 * @type {Record<string, string>}
 * @description The `STATUS_KEYS` object is generated from `STATUS_TYPES` by reversing key-value pairs.
 * It maps each value in `STATUS_TYPES` back to its respective key, allowing for reverse lookup functionality.
 *
 * @example
 * // Assuming STATUS_TYPES is { ACTIVE: "active", INACTIVE: "inactive" }
 * console.log(STATUS_KEYS);
 * // Returns: { "active": "ACTIVE", "inactive": "INACTIVE" }
 */
export const STATUS_KEYS: Record<string, string> = Object.fromEntries(
  Object.entries(STATUS_TYPES).map(([key, value]) => [value, key])
);

/**
 * Sorts an array of users alphabetically by their full names in ascending order.
 *
 * @param {LightUserData[]} users - An array of user data objects to be sorted.
 * Each user object should contain a `fullName` property.
 *
 * @returns {LightUserData[]} - A sorted array of users, ordered by their full names in ascending order.
 *
 * @example
 * // Sort an array of users
 * const users = [
 *   { fullName: 'Jane Doe' },
 *   { fullName: 'John Smith' },
 *   { fullName: 'Alice Johnson' }
 * ];
 * sortUsers(users);
 * // Returns: [
 * //   { fullName: 'Alice Johnson' },
 * //   { fullName: 'Jane Doe' },
 * //   { fullName: 'John Smith' }
 * // ]
 */
export const sortUsers = (users: LightUserData[]): LightUserData[] => {
  if (!users) return [];

  return users?.sort((a, b) => {
    const nameA = a.fullName.toLowerCase();
    const nameB = b.fullName.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

/**
 * Calculates the number of seconds based on the given duration and duration type.
 *
 * @param {number} duration - The duration value to convert.
 * @param {string} [durationType] - The type of the duration to be converted.
 * Valid values are "minutes", "hours", and "days". If no type is provided, the input is treated as seconds.
 *
 * @returns {number} - The equivalent duration in seconds.
 *
 * @example
 * // Convert 2 hours to seconds
 * calculateSeconds(2, 'hours'); // 7200
 *
 * @example
 * // Return the duration as seconds without conversion
 * calculateSeconds(30); // 30
 */
export const calculateSeconds = (
  duration: number,
  durationType?: string
): number => {
  switch (durationType) {
    case "minutes":
      return duration * MINUTE_IN_SECONDS;
    case "hours":
      return duration * HOUR_IN_SECONDS;
    default:
      return duration;
  }
};

/**
 * Checks if an object is empty.
 *
 * @param {Object} obj - The object to be checked.
 *
 * @returns {boolean} - `true` if the object is empty, `false` otherwise.
 *
 * @example
 * // Check if an object is empty
 * isObjectEmpty({}); // true
 * isObjectEmpty({ key: 'value' }); // false
 */
export const isObjectEmpty = (obj?: {} | null): boolean => {
  if (obj === undefined || obj === null) return false;
  return obj && typeof obj === "object" && Object.keys(obj).length === 0;
};

/**
 *  Retrieves the organization name from the organization data.
 * @param organizationId
 * @param organizationData
 * @returns {string} - The name of the organization.
 */

export const getOrganizationName = (
  organizationData: Organization[],
  organizationId: string
): string => {
  if (!organizationData || !organizationId) return "";
  return (
    organizationData.find((org) => org.id === organizationId)?.displayName ?? ""
  );
};

import { all, create } from "mathjs";
import { useTranslation } from "react-i18next";

import { LangKeys } from "@/utils/i18n/languageKeys";

const math = create(all, {});

// There must be ANY type because user can pass variable name (string), 23 (number), tan(var_name) (function)
math.import(
  {
    if: (condition: any, thenValue: any, elseValue: any) =>
      condition ? thenValue : elseValue,
    ifless: (a: any, b: any, c: any, d: any) => (a < b ? c : d),
    ifmore: (a: any, b: any, c: any, d: any) => (a > b ? c : d),
    ifequal: (a: any, b: any, c: any, d: any) => (a === b ? c : d),
    avg: (...args: number[]) => {
      const numbers = args.filter((x) => typeof x === "number");
      if (numbers.length === 0) {
        throw new Error("No numbers provided to avg");
      }
      const sum = numbers.reduce((acc, curr) => acc + curr, 0);
      return sum / numbers.length;
    },
    logn: (a: number, b: number) => {
      if (b <= 0 || b === 1) {
        throw new Error("Base must be greater than 0 and not equal to 1.");
      }
      if (a <= 0) {
        throw new Error("Argument must be greater than 0.");
      }
      return Math.log(a) / Math.log(b);
    },
    loge: (a: number) => {
      if (a <= 0) {
        throw new Error("Argument for loge must be greater than 0.");
      }
      return Math.log(a);
    },
    ceiling: (a: number) => {
      return Math.ceil(a);
    },
    truncate: (a: number) => {
      return Math.trunc(a);
    },
  },
  { override: true }
);

export const OperatorsList = () => {
  const { t } = useTranslation();

  return [
    { value: "+", label: t(LangKeys.ADDITIONAL) },
    { value: "-", label: t(LangKeys.SUBTRATION) },
    { value: "*", label: t(LangKeys.MULTIPLICATION) },
    { value: "/", label: t(LangKeys.DIVISION) },
    { value: "%", label: t(LangKeys.MODULO) },
    { value: "^", label: t(LangKeys.EXPONENTIATION) },
  ];
};

export const MATH_OPERATORS_LIST = ["+", "-", "*", "/", "^", "%"];

export const MATH_CONSTANTS_LIST = [
  { format: "pi", label: "π" },
  { format: "e", label: "e" },
];

export const MATH_FUNCTIONS_LIST = [
  { format: "sin(a)", label: "sin(a)" },
  { format: "cos(a)", label: "cos(a)" },
  { format: "sec(a)", label: "sec(a)" },
  { format: "csc(a)", label: "csc(a)" },
  { format: "tan(a)", label: "tan(a)" },
  { format: "cot(a)", label: "cot(a)" },
  { format: "asin(a)", label: "asin(a)" },
  { format: "acos(a)", label: "acos(a)" },
  { format: "atan(a)", label: "atan(a)" },
  { format: "acot(a)", label: "acot(a)" },
  { format: "loge(a)", label: "loge(a)" },
  { format: "log10(a)", label: "log10(a)" },
  { format: "logn(a; b)", label: "logn(a; b)" },
  { format: "sqrt(a)", label: "sqrt(a)" },
  { format: "abs(a)", label: "abs(a)" },
  { format: "if(a;b;c)", label: "if(a;b;c)" },
  { format: "ifless(a;b;c;d)", label: "ifless(a;b;c;d)" },
  { format: "ifmore(a;b;c;d)", label: "ifmore(a;b;c;d)" },
  { format: "ifequal(a;b;c;d)", label: "ifequal(a;b;c;d)" },
  { format: "ceiling(a)", label: "ceiling(a)" },
  { format: "floor(a)", label: "floor(a)" },
  { format: "truncate(a)", label: "truncate(a)" },
  { format: "round(a)", label: "round(a)" },
  { format: "max(a;b;...)", label: "max(a;b;...)" },
  { format: "min(a;b;...)", label: "min(a;b;...)" },
  { format: "avg(a;b;...)", label: "avg(a;b;...)" },
  { format: "median(a;b;...)", label: "median(a;b;...)" },
  { format: "sum(a;b;...)", label: "sum(a;b;...)" },
  { format: "random()", label: "random()" },
];

/**
 * Replaces special characters in the input string with an underscore.
 * The special characters are defined in the `SPECIAL_CHARTS` constant.
 *
 * @param {string} inputString - The input string to be processed.
 * @returns {string} - The processed string with special characters replaced.
 *
 */

export const replaceSpecialCharacters = (inputString: string): string => {
  const SPECIAL_CHARTS_REGEX = /[;:,.`'"\-_|/\\!?@#$%*^&=(){}[\]<>~]/g;

  const newString = inputString
    .replace("+", "_")
    .replace("(", "_")
    .replace(")", "_");

  return newString.replace(SPECIAL_CHARTS_REGEX, "_");
};

/**
 * Validates the input expression.
 *
 * @param {string} expression - The expression to be validated.
 * @param expressionInput
 * @returns {boolean} - `true` if the expression is valid, `false` otherwise.
 */

export const validateExpression = (
  expression: string,
  expressionInput: { [key: string]: string }
): {
  isValid: boolean;
  error?: string;
} => {
  const convertObject = (
    input: Record<string, string>
  ): Record<string, number> => {
    const output: Record<string, number> = {};

    // We are setting the value of the variable to 2 - this is just for validation for math.js library
    // logn() and loge() need to have a positive number
    // In future we can calculate the expression with variables values from backend
    Object.values(input).forEach((value) => {
      output[value] = 2;
    });

    return output;
  };

  const expressionInputObject = convertObject(expressionInput);

  const replacedExpression =
    replaceVariableKeyToValueInExpressionByExpressionInput(
      expression,
      expressionInput
    ).replaceAll(";", ",");

  try {
    const result = math.evaluate(replacedExpression, {
      ...expressionInputObject,
    });

    if (typeof result === "boolean") {
      return {
        isValid: false,
        error: "Expression cannot evaluate to a boolean value",
      };
    }
    return { isValid: true };
  } catch (error) {
    return {
      isValid: false,
      error: error instanceof Error ? error.message : `${error}`,
    };
  }
};

/**
 * Converts the variable code to variable name.
 *
 * @param {string} expression - The expression to be converted.
 * @param {Object} expressionInput - The object containing the variable code and variable name.
 * @returns {string} - The converted expression.
 */
export const changeVariableCodeToVariableName = (
  expression: string,
  expressionInput: { [key: string]: string }
): string => {
  if (!expression || !expressionInput) {
    return "";
  }

  let newExpression = expression;

  Object.keys(expressionInput).forEach((key) => {
    newExpression = newExpression.replace(
      new RegExp(`\\b${expressionInput[key]}\\b`, "g"),
      key
    );
  });

  return newExpression;
};

/**
 * Checks if the variable is in the expression input.
 *
 * @param {Object} expressionInput - The object containing the variable code and variable name.
 * @param {string} value - The value to be checked.
 * @returns {boolean} - `true` if the variable is in the expression input, `false` otherwise.
 */
export const checkVariableIsInExpressionInput = (
  expressionInput: { [key: string]: string },
  value: string
): boolean => {
  return Object.keys(expressionInput).some((key) => key === `${value}`);
};

/**
 * Adds a new variable to the expression input.
 *
 * @param {Object} expressionInput - The object containing the variable code and variable name.
 * @param {string} value - The value to be added.
 * @returns {Object} - The updated expression input.
 */
export const addNewVariableToExpressionInput = (
  expressionInput: { [key: string]: string },
  value: string
): { [key: string]: string } => {
  const newExpressionInput = { ...expressionInput };

  if (checkVariableIsInExpressionInput(newExpressionInput, value)) {
    return newExpressionInput;
  }

  const uniqueKey = `${value}`;

  newExpressionInput[uniqueKey] = `VAR_${replaceSpecialCharacters(value)}`;

  return newExpressionInput;
};

/**
 * Removes a variable from the expression input.
 *
 * @param expression
 * @param {Object} expressionInput - The object containing the variable code and variable name.
 * @returns {Object} - The updated expression input.
 */
export const replaceVariableKeyToValueInExpressionByExpressionInput = (
  expression: string,
  expressionInput: { [key: string]: string }
): string => {
  let newExpression = expression;

  Object.keys(expressionInput).forEach((key) => {
    // Escape special characters in the key to use in RegExp
    const escapedKey = key.replace(/([.*+?^${}()|[\]\\])/g, "\\$1");
    const pattern = new RegExp(escapedKey, "g"); // Create a global RegExp for the key
    newExpression = newExpression.replace(pattern, expressionInput[key]);
  });

  return newExpression;
};

/**
 *
 * @param expression - The expression to be replaced.
 * @param expressionInput - The object containing the variable code and variable name.
 * @returns {string} - The updated expression.
 */
export const replaceVariableValueToKeyInExpressionByExpressionInput = (
  expression: string,
  expressionInput: { [key: string]: string }
): string => {
  let newExpression = expression;

  Object.keys(expressionInput).forEach((key) => {
    newExpression = newExpression.replace(
      new RegExp(`\\b${expressionInput[key]}\\b`, "g"),
      key
    );
  });

  return newExpression;
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  addOrganization,
  deleteOrganization,
  editOrganization,
  fetchOrganizations,
} from "@/api/organizationSlice/organizationSlice";
import { Organization } from "@/api/organizationSlice/types";
import useNotificationStore from "@/store/useNotificationStore.store";
import { INTERVAL_REFETCH } from "@/utils/consts";
import { errorResponseHandler } from "@/utils/errorResponseHandler";
import i18n from "@/utils/i18n/i18n";
import { LangKeys } from "@/utils/i18n/languageKeys";

import { QUERIES_KEYS } from "../queriesKeys";

type ErrorType = Error & {
  response: {
    data: string | { detail: string };
  };
};

export const useOrganizations = () => {
  return useQuery<Organization[]>({
    queryKey: [QUERIES_KEYS.organizations],
    queryFn: fetchOrganizations,
    refetchInterval: INTERVAL_REFETCH,
  });
};

export const useAddOrganization = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  return useMutation({
    mutationFn: addOrganization,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERIES_KEYS.organizations] });
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_ORGANIZATION_ADDED_SUCCESS),
        "success"
      );
    },
    onError: (error: ErrorType) => {
      openNotification(
        errorResponseHandler(error) ??
          i18n.t(LangKeys.NOTIFICATION_SOMETHING_WENT_WRONG),
        "alert"
      );
    },
  });
};

export const useEditOrganization = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  return useMutation({
    mutationFn: editOrganization,

    onSuccess: () => {
      queryClient.invalidateQueries();
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_ORGANIZATION_UPDATED_SUCCESS),
        "success"
      );
    },
    onError: (error: ErrorType) => {
      openNotification(errorResponseHandler(error), "alert");
    },
  });
};

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  const openNotification = useNotificationStore(
    (state) => state.openNotification
  );

  return useMutation({
    mutationFn: deleteOrganization,

    onSuccess: () => {
      queryClient.invalidateQueries();
      openNotification(
        i18n.t(LangKeys.NOTIFICATION_ORGANIZATION_DELETED_SUCCESS),
        "success"
      );
    },
    onError: (error: ErrorType) => {
      openNotification(errorResponseHandler(error), "alert");
    },
  });
};

import { vestResolver } from "@hookform/resolvers/vest";
import MOCheckbox from "@metsooutotec/modes-web-components/dist/react/checkbox";
import MOOption from "@metsooutotec/modes-web-components/dist/react/option";
import MOSelect from "@metsooutotec/modes-web-components/dist/react/select";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { Button, Icon, Input } from "@/components";
import { useDialog } from "@/components/Dialog/DialogProvider";
import { useFetchResourceConfiguration } from "@/queries/resourcesQuery/resourcesQuery";
import { useEditVariablesConfiguration } from "@/queries/variablesQuery/variablesQuery";
import {
  AveragingTypesList,
  DurationTypesList,
  IfOutOfRangeList,
  RECEIVING_TYPE,
} from "@/utils/consts";
import {
  inputIsNotEmpty,
  inputMustBeEqualOrLessThan,
  inputMustBeEqualOrMoreThan,
} from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";
import { calculateSeconds, getCheckboxValue } from "@/utils/utils";

import styles from "./styles.module.scss";

type EditVariableDialogProps = {
  organizationId?: string;
  resourceId?: string;
  variablesIds: string[];
};

type EditVariableData = {
  variableNames: string[];
  minLimit: number;
  maxLimit: number;
  outOfRange: string;
  averaging: "time-based-averaging" | "simple-based-averaging" | "";
  duration: number;
  expression: string;
  durationType: string;
};

const EditVariableDialog = ({
  organizationId,
  resourceId,
  variablesIds,
}: EditVariableDialogProps) => {
  const [checkboxValue, setCheckboxValue] = useState(false);
  const { hideDialog } = useDialog();
  const { t } = useTranslation();

  const { data: resourceConfig } = useFetchResourceConfiguration({
    organizationId,
    resourceId,
  });
  const { mutate, isPending } = useEditVariablesConfiguration();

  const singleSelectedVariableInfo =
    resourceConfig?.variableConfigurations && variablesIds.length > 1
      ? {
          minLimit: undefined,
          maxLimit: undefined,
          expression: undefined,
          averaging: undefined,
          expressionInput: {},
          maxAge: undefined,
          numberOfSamples: undefined,
          invalidMeasurementHandling: undefined,
          type: undefined,
        }
      : resourceConfig?.variableConfigurations?.[variablesIds[0]];

  useEffect(() => {
    if (singleSelectedVariableInfo)
      setCheckboxValue(
        singleSelectedVariableInfo?.type === RECEIVING_TYPE.discard
      );
  }, [singleSelectedVariableInfo]);

  const schema = create((data: EditVariableData) => {
    inputIsNotEmpty("minLimit", data.minLimit);
    inputIsNotEmpty("maxLimit", data.maxLimit);
    inputIsNotEmpty("outOfRange", data.outOfRange);
    inputIsNotEmpty("duration", data.duration);
    inputMustBeEqualOrMoreThan("duration", Number(data.duration), 0);
    inputMustBeEqualOrMoreThan(
      "minLimit",
      Number(data.minLimit),
      Number(resourceConfig?.defaultVariableConfiguration.minLimit)
    );
    inputMustBeEqualOrLessThan(
      "maxLimit",
      Number(data.maxLimit),
      Number(resourceConfig?.defaultVariableConfiguration.maxLimit)
    );
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditVariableData>({
    mode: "onBlur",
    defaultValues: {
      variableNames: variablesIds,
      ...singleSelectedVariableInfo,
      outOfRange: `${singleSelectedVariableInfo?.invalidMeasurementHandling}`,
      duration: singleSelectedVariableInfo?.maxAge,
    },
    resolver: vestResolver(schema),
  });

  const onSubmit = (data: EditVariableData) => {
    const maxAgeValue = calculateSeconds(
      Number(data.duration),
      data.durationType
    );

    const receivingTypeStatus = checkboxValue
      ? RECEIVING_TYPE.discard
      : RECEIVING_TYPE.continuous;

    const prepareData = {
      variableNames: data.variableNames,
      configuration: {
        maxAge: maxAgeValue,
        minLimit: Number(data.minLimit),
        maxLimit: Number(data.maxLimit),
        invalidMeasurementHandling: Number(data.outOfRange),
        expression: data.expression,
        expressionInput: {},
        type: receivingTypeStatus,
      },
    };

    mutate({
      organizationId,
      resourceId,
      body: prepareData,
    });
  };

  const handleCheckboxChange = (event: Event) => {
    const value = getCheckboxValue(event);
    setCheckboxValue(value);
  };

  return (
    <div>
      <h3 className={styles.dialogHeader}>{t(LangKeys.EDIT_VARIABLE)}</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formStyles}>
          <div className={styles.twoInputsWrapper}>
            <div className={styles.inputWrapper}>
              <Input
                error={errors.minLimit !== undefined}
                errorText={errors.minLimit?.message}
                label={t(LangKeys.MIN_VALUE)}
                placeholder={t(LangKeys.VALUE)}
                className={styles.valueInput}
                disabled={checkboxValue}
                {...register("minLimit")}
              />
            </div>

            <div className={styles.inputWrapper}>
              <Input
                error={errors.maxLimit !== undefined}
                errorText={errors.maxLimit?.message}
                label={t(LangKeys.MAX_VALUE)}
                className={styles.valueInput}
                placeholder={t(LangKeys.VALUE)}
                disabled={checkboxValue}
                {...register("maxLimit")}
              />
            </div>
          </div>

          {!checkboxValue && (
            <MOSelect
              error={errors.outOfRange !== undefined}
              errorText={errors.outOfRange?.message}
              label={t(LangKeys.IF_OUT_OF_RANGE)}
              placeholder={t(LangKeys.IF_OUT_OF_RANGE)}
              placement="bottom"
              onMoAfterHide={(e: any) => e.stopPropagation()}
              disabled={checkboxValue}
              {...register("outOfRange")}
            >
              {IfOutOfRangeList.map((item) => (
                <MOOption key={item.value} value={`${item.value}`}>
                  {item.name}
                </MOOption>
              ))}
            </MOSelect>
          )}

          <MOSelect
            label={t(LangKeys.AVERAGING)}
            placeholder={t(LangKeys.SELECT_AVERAGING_TYPE)}
            placement="bottom"
            onMoAfterHide={(e: any) => e.stopPropagation()}
            {...register("averaging")}
            // TODO in future
            // For now can be select only one type of averaging
            // TASK 1. point: https://metso.atlassian.net/jira/software/c/projects/DTWIN/boards/2497?assignee=712020%3Aa41cff2d-d2c6-4684-b062-c72264faabba&quickFilter=13358&selectedIssue=DTWIN-6235
            value={AveragingTypesList[0].value} // time-based-averaging
            disabled
          >
            {AveragingTypesList.map((item) => (
              <MOOption key={item.value} value={item.value}>
                {item.name}
              </MOOption>
            ))}
          </MOSelect>

          <div className={styles.durationWrapper}>
            <Input
              error={errors.duration !== undefined}
              label={t(LangKeys.DURATION)}
              placeholder={t(LangKeys.VALUE)}
              disabled={checkboxValue}
              {...register("duration")}
            />

            <Icon name="arrow-right" className={styles.icon} />

            <MOSelect
              placement="bottom"
              placeholder={t(LangKeys.SELECT_DURATION_TYPE)}
              onMoAfterHide={(e: any) => e.stopPropagation()}
              {...register("durationType")}
              value={DurationTypesList[0].value} // seconds
              disabled={checkboxValue}
            >
              {DurationTypesList.map((item) => (
                <MOOption key={item.value} value={item.value}>
                  {item.name}
                </MOOption>
              ))}
            </MOSelect>
          </div>

          {errors.duration && (
            <p className={styles.errorText}>
              {typeof errors?.duration?.message === "string"
                ? errors.duration.message
                : ""}
            </p>
          )}

          <MOCheckbox checked={checkboxValue} onMoChange={handleCheckboxChange}>
            {t(LangKeys.DISABLED_VARIABLE)}
          </MOCheckbox>
        </div>

        <div className={styles.dialogFooter}>
          <Button type="button" variant="subtle" onClick={hideDialog}>
            {t(LangKeys.CANCEL)}
          </Button>

          <Button type="submit" variant="primary" loading={isPending}>
            {t(LangKeys.APPLY)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditVariableDialog;

import MOBreadcrumb from "@metsooutotec/modes-web-components/dist/react/breadcrumb";
import MOBreadcrumbItem from "@metsooutotec/modes-web-components/dist/react/breadcrumb-item";

type BreadcrumbsProps = React.ComponentProps<typeof MOBreadcrumb> & {
  links: { label: string; url: string }[];
};

const Breadcrumbs = ({ links, ...props }: BreadcrumbsProps) => {
  return (
    <MOBreadcrumb {...props}>
      {links
        .filter((link) => link.label.trim() !== "")
        .map((link) => (
          <MOBreadcrumbItem key={link.url} href={link.url}>
            {link.label}
          </MOBreadcrumbItem>
        ))}
    </MOBreadcrumb>
  );
};

export default Breadcrumbs;

import MOIconButton from "@metsooutotec/modes-web-components/dist/react/icon-button";
import { CellClassParams, ColDef, RowClickedEvent } from "ag-grid-community";
import { CustomFilterProps } from "ag-grid-react";
import { useTranslation } from "react-i18next";

import FilterByList, {
  getFilterOptions,
} from "@/components/DataGrid/GridFilters/FilterByList/FilterByList";
import FilterByString from "@/components/DataGrid/GridFilters/FilterByString/FilterByString";
import { getRangeName } from "@/utils/getRangeName";
import { LangKeys } from "@/utils/i18n/languageKeys";

import { useDialog } from "../Dialog/DialogProvider";
import DeleteVariableDialog from "../Forms/DeleteVariableDialog";
import { changeVariableCodeToVariableName } from "../MathOperationInput/utils";

type DerivedPlantVariableColumnsProps = {
  organizationId?: string;
  resourceId?: string;
};

const isDisabled = (params: CellClassParams) => {
  return params.data.receivingType === "disabled";
};

const useDerivedPlantVariableColumns = ({
  organizationId,
  resourceId,
}: DerivedPlantVariableColumnsProps) => {
  const { t } = useTranslation();
  const { showDialog } = useDialog();

  const columnsDef: ColDef[] = [
    {
      field: "variableId",
      hide: true,
    },
    {
      headerName: t(LangKeys.VARIABLE),
      field: "variableName",
      width: 444,
      sortable: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      filter: ({ onModelChange, ...params }: CustomFilterProps) => (
        <FilterByString onModelChange={onModelChange} {...params} />
      ),
      cellClassRules: {
        "disabled-row": isDisabled,
      },
    },
    {
      headerName: t(LangKeys.MIN_VALUE),
      flex: 1,
      field: "minLimit",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellClassRules: {
        "disabled-row": isDisabled,
      },
    },
    {
      headerName: t(LangKeys.MAX_VALUE),
      flex: 1,
      field: "maxLimit",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellClassRules: {
        "disabled-row": isDisabled,
      },
    },
    {
      headerName: "If out of range",
      flex: 1,
      field: "ifOutOfRange",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      filter: (params: CustomFilterProps) => (
        <FilterByList
          filterOptions={getFilterOptions("IF_OUT_OF_RANGE")}
          {...params}
        />
      ),
      cellRenderer: (api: RowClickedEvent) => {
        return <div>{getRangeName(api.data.outOfRange)}</div>;
      },
      cellClassRules: {
        "disabled-row": isDisabled,
      },
    },
    {
      headerName: "Formula",
      flex: 1,
      field: "expression",
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
      cellClassRules: {
        "disabled-row": isDisabled,
      },
      cellRenderer: (api: RowClickedEvent) => {
        return (
          <>
            {changeVariableCodeToVariableName(
              api.data.expression,
              api.data.expressionInput
            )}
          </>
        );
      },
    },
    {
      field: "edit",
      width: 80,
      headerComponent: () => (
        <div>
          <MOIconButton name="trash" />
        </div>
      ),
      cellRenderer: (api: RowClickedEvent) => {
        return (
          <MOIconButton
            name="trash"
            onClick={(e) => {
              e.stopPropagation();
              showDialog(
                <DeleteVariableDialog
                  organizationId={organizationId}
                  resourceId={resourceId}
                  variableId={`${api.data.variableId}`}
                />,
                "small"
              );
            }}
          />
        );
      },
      cellClass: "cell-style",
      headerClass: "header-style-checkbox",
    },
  ];

  return columnsDef;
};

export default useDerivedPlantVariableColumns;

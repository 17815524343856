import { vestResolver } from "@hookform/resolvers/vest";
import MOOption from "@metsooutotec/modes-web-components/dist/react/option";
import MOSelect from "@metsooutotec/modes-web-components/dist/react/select";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { create } from "vest";

import { Project } from "@/api/projectSlice/types";
import { InviteUser } from "@/api/userSlice/types";
import Button from "@/components/Button";
import { useDialog } from "@/components/Dialog/DialogProvider";
import Input from "@/components/Input";
import { useGetEmails, useInviteUser } from "@/queries/userQuery/userQuery";
import { inputIsNotEmpty } from "@/utils/formValidation/formValidation";
import { LangKeys } from "@/utils/i18n/languageKeys";

import styles from "./invitenewuser.module.scss";

const schema = create((data: InviteUser) => {
  inputIsNotEmpty("userEmail", data.userEmail);
});

type InviteNewUserProps = {
  organizationId: string;
  projects?: Project[];
};

const InviteNewUser = ({ organizationId, projects }: InviteNewUserProps) => {
  const { hideDialog } = useDialog();
  const { t } = useTranslation();
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const [emailSearchValue, setEmailSearchValue] = useState("");

  const { data: emailsList } = useGetEmails(emailSearchValue);
  const { mutate, isSuccess, isPending } = useInviteUser();

  const options =
    emailsList?.map((user) => ({
      value: user.emailAddress,
      label: `${user.emailAddress}`,
    })) || [];

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<InviteUser>({
    defaultValues: {
      userEmail: "",
      projectId: "",
      roles: [],
    },
    resolver: vestResolver(schema),
  });

  const handleInviteUser = async (data: InviteUser) => {
    mutate({
      userEmail: data.userEmail,
      organizationId,
      projectId: data.projectId,
      roles: data.roles,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      hideDialog();
    }
  }, [isSuccess, hideDialog]);

  const selectEmail = (email: string) => {
    setValue("userEmail", email);
    setEmailSearchValue("");
  };

  const emailList = useMemo(() => {
    if (emailsList?.length === 0) return;

    return (
      <div className={styles.emailsList}>
        {options.map((option) => (
          <p onClick={() => selectEmail(option.value)} key={option.value}>
            {option.label}
          </p>
        ))}
      </div>
    );
  }, [emailsList]);

  return (
    <div>
      <h2 className={styles.header}>{t(LangKeys.USER_DETAILS)}</h2>

      <form
        onSubmit={handleSubmit(handleInviteUser)}
        className={styles.formBox}
      >
        <div>
          <Input
            label={t(LangKeys.USER_EMAIL)}
            placeholder={t(LangKeys.USER_EMAIL)}
            error={errors.userEmail !== undefined}
            onMoInput={(e) =>
              setEmailSearchValue((e.target as HTMLSelectElement).value)
            }
            {...register("userEmail")}
          />

          {emailList}
        </div>

        <MOSelect
          clearable
          placeholder={t(LangKeys.SELECT_PROJECT)}
          {...register("projectId")}
          label={t(LangKeys.PROJECT)}
          onMoChange={(event: Event) => {
            const target = event.target as HTMLInputElement;
            const id = target.value as string;
            setSelectedProjectId(id);
          }}
          onMoAfterHide={(e: any) => e.stopPropagation()}
        >
          {projects?.map((project) => {
            return (
              <MOOption key={project.id} value={project.id}>
                {project.name || (
                  <>
                    {" "}
                    <b>{t(LangKeys.NO_NAME)}</b> {project.id}{" "}
                  </>
                )}
              </MOOption>
            );
          })}
        </MOSelect>

        <MOSelect
          multiple
          clearable
          disabled={
            selectedProjectId === undefined || selectedProjectId?.length === 0
          }
          placeholder={t(LangKeys.SELECT_ROLES)}
          {...register("roles")}
          label={t(LangKeys.ROLES)}
          onMoAfterHide={(e: any) => e.stopPropagation()}
        >
          {projects?.map((x) => {
            if (x.id === selectedProjectId) {
              return x.roles.map((r) => {
                return (
                  <MOOption key={r.id} value={r.id}>
                    {r.displayName}
                  </MOOption>
                );
              });
            }
          })}
        </MOSelect>

        <div className={styles.buttonsBox}>
          <Button
            variant="subtle"
            className={styles.buttons}
            onClick={hideDialog}
          >
            {t(LangKeys.CANCEL)}
          </Button>

          <Button className={styles.buttons} type="submit" loading={isPending}>
            {t(LangKeys.CONFIRM)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InviteNewUser;
